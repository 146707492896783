<template>
	<div>
		<section class="section is-main-section">
			<finance-table
				ref="table"
				:settings="table.settings"
				:filter="table.filter"
				:page="table.page" />
		</section>
	</div>
</template>

<script>
	import {
		SiteService,
		DownloadService,
		BankListService,
		TransactionService as Service,
	} from "@/services"
	import StatusChangeModal from "./StatusChange"
	import LogModal from "./Log"
	import TransactionService from "@/services/transaction.service"
	import DepositStatus from "@/components/DepositStatus"

	export default {
		name: "DepositList",
		data() {
			return {
				table: {
					filter: [
						{
							type: "input",
							value: "DEPOSIT",
							hidden: true,
							field: "type",
						},
						{
							type: "enum",
							value: null,
							field: "bankType",
							name: this.$t('def.method'),
							enumType: "bankTypes",
						},
						{
							type: "input",
							value: null,
							field: "uuid",
							name: this.$t('def.transactionId'),
						},
						{
							type: "input",
							value: null,
							field: "processId",
							name: this.$t('def.processId'),
						},
						{
							type: "input",
							value: null,
							field: "name",
							name: this.$t('def.user'),
						},
						{
							type: "input",
							value: null,
							field: "userId",
							name: this.$t('def.userId'),
						},
						{
							type: "input",
							value: null,
							field: "amount",
							name: this.$t('def.amount'),
						},
						{
							type: "enum",
							value: null,
							field: "status",
							name:this.$t('def.status'),
							enumType: "transactionTypes",
						},
						{
							type: "input",
							value: null,
							field: "group",
							name: "Grup",
						},
						{
							type: "datepicker",
							value: [new Date(), new Date()],
							field: "dates",
							name: this.$t('def.requestDate'),
						},
						{
							type: "datepicker",
							value: [null, null],
							field: "updatedDates",
							name: this.$t('def.approveDate'),
						},
					],
					page: {
						title: this.$t('def.deposits'),
						icon: "cash-multiple",
						actions: [
							{
								title: this.$t('def.filter'),
								type: "info",
								icon: "sync",
								action: () => {
									this.$refs.table.loadAsyncData()
								},
							},
							{
								title: "Export",
								type: "success",
								icon: "file-excel",
								perm: "Deposit.ExcelExport",
								action: () => {
									this.getExportExcel()
								},
							},
						],
					},
					settings: {
						service: Service,
						method: "list",
						columns: [
							{
								field: "status",
								label: this.$t('def.status'),
								sortable: true,
								renderComponent: DepositStatus,
							},
							{
								field: "site.name",
								label: this.$t('def.site'),
							},
							{
								field: "uuid",
								label: this.$t('def.transactionId'),
								sortable: true,
							},
							{
								field: "bank.type",
								label: this.$t('def.bank'),
								renderHtml: true,
								column: "row",
								viewMethod: (val) => {
									let logo =
										process.env.VUE_APP_SITE_URL +
										"logos/banks/papara.png"

									if (val.bank.type === "PAYFIX")
										logo = process.env.VUE_APP_SITE_URL +"logos/banks/payfix.png"
									if (val.bankAccount.method === "ISYERIMPOS")
										logo = process.env.VUE_APP_SITE_URL +"logos/banks/isyerimpos.svg"
									if (val.bankAccount.method === "NAYS")
										logo = process.env.VUE_APP_SITE_URL +"logos/banks/nays.svg"
                  if (val.bankAccount.method === "CMT")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/cmt.png"
                  if (val.bankAccount.method === "PAYMAGO")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/paymago.png"
                  if (val.bankAccount.method === "PAYCO")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/payco.png"
                  if (val.bankAccount.method === "PAYTURCA")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/payturca.jpg"
                  if (val.bankAccount.name === "VEVO PAPARA")
                    logo = process.env.VUE_APP_SITE_URL +"logos/banks/vpapara.svg"
									if (
										val.bank.type === "BANK_TRANSFER" ||
										val.bank.type === "FOREX"
									) {
										logo = val.bankAccount?.bankList?.logo
									}
									return `<img width="70" src="${logo}" />`
								},
							},
							{
								field: "bankAccount.name",
								label:this.$t('def.account'),
								column: 'row',
								renderHtml: true,
								viewMethod: val => val.bankAccount.name.length > 30 ? val.bankAccount.name.slice(0,30) + '..' : val.bankAccount.name
							},
							{
								field: "bankAccount.iban",
								label: this.$t('def.iban'),
							},
							{
								field: "username",
								label:this.$t('def.user'),
								sortable: true,
								column: "row",
								renderHtml: true,
								viewMethod: (val) =>
									`${val.name} ${
										val.customer && val.customer.isProblem
											? '<b class="has-text-danger is-underlined">(Problemli)</b>'
											: ""
									}`,
							},
							{
								field: "userId",
								label: this.$t('def.userId'),
							},
							{
								field: "bankAccount.group",
								label: this.$t('def.group'),
								sortable: true,
								renderHtml: true,
								column: "row",
								viewMethod: (val) =>
									`${
										val.bankAccount.group === "ALL"
											? "-"
											: val.bankAccount.group
									}`,
							},
							{
								field: "total",
								label: this.$t('def.amount'),
								column: "row",
								width: '200px',
								renderHtml: true,
								viewMethod: (val) => {
									if	(val.fromCurrency === 'USDT') {
										return `<div class="is-flex is-justify-content-center is-flex-direction-column"><img style="height:13px" class="mr-2" src="${require('./../../../public/logos/' + this.appName + '\/crypto.svg')}" /><span style="line-height: 15px">${val.total}</span></div>`
									} else {
										return `${this.currencies[val.fromCurrency]}${this.numberFormat(val.total)}`
									}
								}
							},
							{
								field: "createdAt",
								label: this.$t('def.requestDate'),
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTimeS
									),
							},
							{
								field: "updatedAt",
								label: this.$t('def.approveDate'),
								sortable: true,
								viewMethod: (val) =>
									this.dateFormat(
										val,
										this.dateFormats.normalDateTimeS
									),
							},
							{
								field: "actions",
								column: "id",
								label: this.$t('def.actions'),
								width: "100px",
								actions: [
									{
										title: this.$t('def.update'),
										type: "info",
										column: "row",
										perm: "Deposit.StatusChange",
										icon: "file-edit-outline",
										action: (data) => {
											this.openModal({
												component: StatusChangeModal,
												props: {
													withdraw: { ...data },
												},
												close: this.$refs.table.loadAsyncData,
											})
										},
									},
									{
										title: this.$t('def.logs'),
										type: "warning",
										perm: "Deposit.Log",
										column: "row",
										icon: "format-list-text",
										size: "is-small",
										class: "has-black",
										action: (data) => {
											this.openModal({
												component: LogModal,
												styles: {
													width: 1000,
													height: 800,
												},
												props: {
													withdraw: { ...data },
												},
												// close: this.$refs.table.loadAsyncData,
											})
										},
									},
									{
										title: this.$t('def.investmentDetail'),
										type: "dark",
										perm: "Investor.Detail",
										column: "row",
										icon: "account-search",
										size: "is-small",
										class: "has-white",
										action: (data) => {
											this.$router.push({
												name: "investorDetail",
												params: {
													id: data.customer.uuid,
												},
											})
										},
									},
								],
							},
						],
					},
				},
			}
		},
		computed: {
			insertListener() {
				return `transactionDepositInserted${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
			updateListener() {
				return `transactionDepositUpdated${
					this.$store.state.user.group !== "ALL"
						? this.$store.state.user.group
						: this.$store.state.user.siteId || ""
				}`
			},
		},
		async mounted() {
			await this.getBankList()
			if (!this.$store.state.user.siteId) await this.getSites()
			if (this.$store.state.setting.socket) {
				this.insert()
				this.update()
			}
		},
		methods: {
			getSites() {
				SiteService.all({ status: 1 }).then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "siteId",
						name: "Site",
					})
				})
			},
			getBankList() {
				BankListService.all().then(({ data }) => {
					this.table.filter.unshift({
						type: "select",
						value: null,
						data,
						field: "bankListId",
						name: "Banka",
					})
				})
			},
			getExportExcel() {
				let filterParams = {}
				if (
					this.$refs.table.filter &&
					this.$refs.table.filter.length > 0
				) {
					this.$refs.table.filter.map((item) => {
						if (
							(item.value !== "" && item.value !== null) ||
							item.hidden === true
						) {
							if (item.type === "datepicker") {
								if (
									typeof item.value === "object" &&
									item.value.length > 0
								) {
									if (item.value[0] && item.value[1]) {
										const startDate = this.getIstanbulDate(
											item.value[0]
										)
										const endDate = this.getIstanbulDate(
											item.value[1],
											false
										)
										filterParams[item.field] = [
											startDate,
											endDate,
										]
									}
								} else {
									filterParams[item.field] = item.value
								}
							} else {
								filterParams[item.field] = item.value
							}
						}
					})
				}

				TransactionService.exportExcel({
					...filterParams,
					offset: 180,
				}).then(({ data: { path, filename } }) => {
					DownloadService.download({ path }).then((res2) => {
						const url = URL.createObjectURL(
							new Blob([res2.data], {
								type: "application/vnd.ms-excel",
							})
						)
						const link = document.createElement("a")
						link.href = url
						link.setAttribute("download", filename)
						document.body.appendChild(link)
						link.click()
					})
				})
			},
			update() {
				this.sockets.subscribe(this.updateListener, (val) => {
					val = JSON.parse(val)
					if (!val.status || !val.uuid || this.$refs.table.loading)
						return
					let newDatas = {
						WAITING: [],
						PENDING: [],
						SUCCESSFUL: [],
						UNSUCCESSFUL: [],
					}
					this.$refs.table.data.map((item, key) => {
						if (item.uuid === val.uuid) {
							item.status = val.status
							item.updatedAt = new Date(val.updatedAt)
							item.logs = val.logs
						}
						newDatas[item.status].push(item)
					})
					newDatas["WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PENDING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["SUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["UNSUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["WAITING"])
							.concat(newDatas["PENDING"])
							.concat(newDatas["SUCCESSFUL"])
							.concat(newDatas["UNSUCCESSFUL"]),
					]
				})
			},
			insert() {
				this.sockets.subscribe(this.insertListener, (val) => {
					if (this.$refs.table.pagination.page > 1) return
					val = JSON.parse(val)
					const bankListId = this.$refs.table.filter[0].value || null
					const site =
						this.$store.state.user.siteId ||
						this.$refs.table.filter[1].value ||
						null
					const bankType = this.$refs.table.filter[3].value || null
					const uuid = this.$refs.table.filter[4].value || null
					const processId = this.$refs.table.filter[5].value || null
					const name = this.$refs.table.filter[6].value || null
					const userId = this.$refs.table.filter[7].value || null
					const amount = this.$refs.table.filter[8].value || null
					const status = this.$refs.table.filter[9].value || null
					const group = this.$refs.table.filter[10].value || null
					if (
						(!site || val.site.uuid === site) &&
						(!uuid || val.uuid === uuid) &&
						(!processId || val.processId === processId) &&
						(!amount ||
							parseFloat(val.total) === parseFloat(amount)) &&
						(!name || val.name === name) &&
						(!bankType || val.bankType === bankType) &&
						(!bankListId ||
							val.bankAccount.bankName === bankListId) &&
						(!group || val.group === group) &&
						(!userId || val.userId === userId) &&
						(!status || val.status === status)
					) {
						if (!this.$refs.table.loading && this.$refs.table.data)
							this.$refs.table.data.unshift(val)
						// this.$refs.table.data.pop()
					}

					let newDatas = {
						WAITING: [],
						PENDING: [],
						SUCCESSFUL: [],
						UNSUCCESSFUL: [],
					}
					this.$refs.table.data.map((item) =>
						newDatas[item.status].push(item)
					)
					newDatas["WAITING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["PENDING"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["SUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					newDatas["UNSUCCESSFUL"].sort(
						(a, b) => a.createdAt - b.createdAt
					)
					this.$refs.table.data = [
						...[]
							.concat(newDatas["WAITING"])
							.concat(newDatas["PENDING"])
							.concat(newDatas["SUCCESSFUL"])
							.concat(newDatas["UNSUCCESSFUL"]),
					]
				})
			},
			unsubscribe() {
				this.sockets.unsubscribe(this.updateListener)
				this.sockets.unsubscribe(this.insertListener)
			},
		},
		beforeDestroy() {
			if (this.$store.state.setting.socket) this.unsubscribe()
		},
		watch: {
			"$store.state.setting.socket": function () {
				if (this.$store.state.setting.socket) {
					this.$refs.table.loadAsyncData()
					this.insert()
					this.update()
				} else {
					this.unsubscribe()
				}
			},
		},
	}
</script>
